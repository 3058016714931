<template>
  <PageBuilder />
</template>
<script setup lang="ts">
import { useNuxtApp } from '#imports';
import { onMounted } from 'vue';

const { $lenisMount } = useNuxtApp();

onMounted(() => {
  $lenisMount();
});
</script>
